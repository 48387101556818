import axios from "axios";
import { getData } from "../store/localStorgae";

export const getGroup = async () => {
    const token = getData('token')
    const baseURL =  "https://proxy.jksconsultants.com";

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/Users/get?Login=6666`,
        headers: {
            'accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    };
    let group = null;
    await axios.request(config)
        .then((response) => {
            const data = response.data.data
            group =  data.group
        })
        .catch((error) => {
            console.error('setting group:', error);
        });

    return group

}