import axios from "axios";
import { getData } from "../store/localStorgae";

export const getNewToken = async () => {
    let data = {
        "username": "6666",
        "password": "abcd1234"
      }
    // try {
    //     loginData = await getData('loginData')
    // }
    // catch (error) {
    //     console.log('error getting loginData', error)
    // }
    // let data = JSON.stringify(loginData);
    // const baseURL = await getData('baseURL')
    const baseURL =  "https://proxy.jksconsultants.com";

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/login`,
        headers: {
            'accept': 'text/plain',
            'Content-Type': 'application/json'
        },
        data: data
    };
    let token;
    console.log("will fetch new token")
    await axios.request(config)
        .then((response) => {
            if (response.data.success === true) {
                token = response.data.data;
                console.log("fetched new token")

            } else {
                console.error("request token error", response.data.message)
            }
        })
        .catch((error) => {
            console.log(`get token error ${error}`);
        });
    return token
}