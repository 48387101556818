import React, { useEffect, useState } from 'react';
import { useAllCurrenciesStore, useCurrentCurrency, useDesktopViewStatus, useStarredCurrenciesStore, useTokenStore, useWatchListStore, useWindowWidth } from '../store';
import styles from './WatchList.module.css'
import axios, { all } from 'axios';
import { getGroup } from '../helper/getGroup';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getCurrencies } from '../helper/getCurrencies';
import { lazy, Suspense } from 'react';
import { FixedSizeList } from "react-window";
import Draggable from 'react-draggable';
import { Modal, Button } from 'antd';
import { CiSearch } from "react-icons/ci";
import { IoAddSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { useStore } from 'zustand';
import { saveData } from '../store/localStorgae';

const CurrencyRow = ({ item, selectedCurrency, setSelectedCurrency }) => {
    const { token, setToken } = useTokenStore()
    const { allCurrencies } = useAllCurrenciesStore()
    const {watchList, setWatchList} = useWatchListStore()
    const { setCurrentCurrency } = useCurrentCurrency()




    const WS_URL = `wss://copyapi.jksconsultants.com/ws`
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL, {
        protocols: ['client', token],
        shouldReconnect: (closeEvent) => true,
        onError: (error) => console.error(`[Forex] websocket error: ${JSON.stringify(error)}`)
    },
    Boolean(token)
    );

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        if (connectionStatus == 'Open') {
            // [needs to be fixed]
            // works only when sendJsonMessage is called twice
            // shows same behaviour on Web socket clients
            // probably a server issue
            sendJsonMessage({
                item: "candle",
                command: "subscribe",
                data: item.name
            })
            sendJsonMessage({
                item: "candle",
                command: "subscribe",
                data: item.name
            })
        }
    }, [connectionStatus])

    const [visible, setVisible] = useState(true)
    const getDateTime = (datetime_msc) => {
        const datetimeObject = new Date(datetime_msc);
        // Extract individual components
        const hours = datetimeObject.getHours();
        const minutes = datetimeObject.getMinutes();
        // Format the components as needed
        const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
        return formattedTime;
    };

    const getMultiplier = (decimalLength) => {
        if (decimalLength < 2) {
            return 1
        }
        else {
            return Math.pow(10, (decimalLength - 1))
        }
    }

    const roundVolume = (originalNumber) => {
        let roundedNumber = Number(originalNumber.toFixed(2));

        // Extract the last decimal place
        let lastDecimalPlace = roundedNumber * 100 % 10;

        if (lastDecimalPlace === 0 && roundedNumber % 1 !== 0) {
            roundedNumber = (roundedNumber * 10 + 1) / 10;
        }

        return roundedNumber;
    }





    const [initialData, setInitialData] = useState()

    useEffect(() => {
        const fetchData = async () => {
            const group = await getGroup()
            const baseURL =  "https://proxy.jksconsultants.com";

            console.log('group', group)
            const API_URL = `${baseURL}/Chart/ticklast?symbol=${item.name}&group=${group}`;
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: API_URL,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/plain',
                    Authorization: `Bearer ${token}`,
                }
            };
            await axios
                .request(config)
                .then((response) => {
                    const data = response.data.data;
                    const normalizedData = {
                        Datetime: data.dateTime,
                        Bid: data.bid,
                        Ask: data.ask,
                        Last: data.last,
                        Volume: data.volume,
                        Datetime_msc: data.datetime_msc,
                        Volume_ext: data.volume_ext
                    }
                    setInitialData(normalizedData)
                })
                .catch((error) => {
                    console.error("[Forex] Error fetching initial data:", error);

                });

        }
        if (token) fetchData();
    }, [item.name, token]);

    const data =
        lastJsonMessage ? lastJsonMessage.data :
            initialData;

            


    useEffect(() => {
        if (data) {
            if (data.Ask) {
                setVisible(true)
            }
            else {
                setVisible(false)
            }
        }
    }, [data])

    const remoteItem = () => {
        const filteredList = watchList.filter(i => (i !== item))
        setWatchList(filteredList)
        saveData('watchList', filteredList)
    }

    
    return (
    
       
        <div onClick={() => {setCurrentCurrency(item.name); setSelectedCurrency(item.name)}} style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '1rem', paddingRight: '1rem', border: selectedCurrency === item.name ? "2px solid #2761FD" : "2px solid transparent", borderBottom: selectedCurrency === item.name ? "2px solid #2761FD" : '1px solid #F1F3FA', cursor: 'pointer' }} className={styles.row}>
            <div className={styles.symbolCol}>
                <div style={{ width: '2rem' }}>
                    <img style={{width: '2rem'}} src={`https://jksconsultants.com/api/currencies/images/${item.name}.svg`}
                     onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src="/assets/placeholder.svg";
                      }}
                    ></img>
                </div>
                <div style={{ marginLeft: '.25rem' }}>{item.name}</div>
            </div>
            <div className={styles.volumeCol}>
                [{data ? roundVolume((data.Ask - data.Bid) * getMultiplier(parseInt(item.decimal))) : 0}]
            </div>
            <div style={{ color: 'red' }} className={styles.bidAskCol}>
                {data ? data.Bid?.toFixed(item.decimal) : 0}

            </div>
            <div style={{ color: 'green' }} className={styles.bidAskCol}>
                {data ? data.Ask?.toFixed(item.decimal) : 0}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', }} >
                <div style={{ position: 'absolute', right: 0,  alignItems: 'center', display:'flex',  }} className={styles.crossBtn} onClick={() => remoteItem()}><RxCross2 className={styles.crossIcon} size={20} /></div>
            </div>
        </div>

    )
}


export const WatchList = () => {

    const { token, setToken } = useTokenStore()
    const { allCurrencies, setAllCurrencies } = useAllCurrenciesStore()
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const { desktopView, setDesktopView } = useDesktopViewStatus()



    useEffect(() => {
        // const fetchJksCurrencies = () => {

        //     const data = getCurrencies();
        //     setAllCurrencies(data)

        // };


        // fetchJksCurrencies()
        const fetchAllCurrencies = async () => {
            const group = await getGroup()

            const baseURL =  "https://proxy.jksconsultants.com";

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${baseURL}/Group/symbol?group=${group}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            await axios.request(config)
                .then(async (response) => {
                    const data = await response.data.data;
                    const normalizedData = await data.map(item => ({
                        name: item.symbol,
                        desc: item.description,
                        decimal: item.digits
                    }))
                    await setAllCurrencies(normalizedData)
                    saveData('allCurrencies', normalizedData)
                })
                .catch((error) => {
                    console.error("allcurrencies error", error);
                });
        }
        if (token) fetchAllCurrencies()
    }, [token]);

    const dragRef = React.createRef();

    const [modalOpen, setModalOpen] = useState(false)
    const [inputValue, setInputValue] = useState(''); // Initialize state

    const handleInputChange = (event) => {
        setInputValue(event.target.value); // Update state on input change
    };
 
    const handleModalCancel = () => {
        setModalOpen(false)
    }


    const {watchList, setWatchList} = useWatchListStore()

    useEffect(() => {
        setDesktopView(window.innerWidth > 600)
    }, []) 

    const { screenWidth, setScreenWidth } = useWindowWidth();


    return (
        
        <div style={{ display: 'flex', flexGrow: 1, flexShrink: 1, flexBasis: '10rem', flexDirection: 'column', height: desktopView ? '100vh' : '90vh', overflowY: 'auto', backgroundColor: 'white', marginTop: desktopView ? '38px' : 0, borderTop: desktopView ? '4px solid #E1E3EB' : 0,}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', paddingTop: '.5rem', paddingBottom: '.125rem', alignItems: 'center' }}>
                <div style={{ fontSize: '14px', fontWeight: 600, color: "#131722" }}>Watchlist</div>
                <Button type='text' onClick={() => setModalOpen(true)} ><IoAddSharp color="#131722" size={20} /></Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '1rem', paddingRight: '1.5rem', paddingBottom: '.125rem', paddingTop: 0, alignItems: 'center' }}>
                <div className={styles.symbolColTitle}>
                    <div style={{ marginLeft: '.25rem' }}>Symbol</div>
                </div>
                <div className={styles.volumeColTitle}>
                    Volume
                </div>
                <div className={styles.bidAskColTitle}>
                    Bid
                </div>
                <div className={styles.bidAskColTitle}>
                    Ask
                </div>
            </div>
            <div style={{ width: '100%', borderBottom: '1px solid #E1E3EB', marginBottom: '2rem' }} />
            <Modal
                open={modalOpen}
                mask={false}
                maskClosable={true}
                keyboard={false}
                wrapClassName="aaa"
                width={desktopView ? 500 : screenWidth - 100}
                className={styles.antd}
                onCancel={handleModalCancel}

                style={{
                    position: 'fixed',
                    // transform: 'translateX(-50%)',
                    left: desktopView ? ((document.body.clientWidth - 500) / 2) : 50,
                    padding: 0

                }}
                footer={false}
                // zIndex={-1}

                //   onOk={this.handleOk}
                //   onCancel={this.handleCancel}
                modalRender={modal => {
                    if (desktopView) {
                      return (<Draggable
                      >
                        <div aa="2" ref={dragRef} >
                          {modal}
                        </div>
                      </Draggable>)
                    }
                    else return modal
                  }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontSize: '20px', fontWeight: 600, padding: '1rem', paddingTop: 0 }}>Add symbol</div>
                    <div

                        style={{
                            border: '1px solid #e0e3eb', borderLeft: 0, borderRight: 0,
                            display: 'flex', alignItems: 'center', marginBottom: '.5rem'
                        }}

                    >
                        <CiSearch size={25} style={{ color: "#a3a6af", paddingLeft: '1rem' }} />
                        <input
                            placeholder='Search'
                            type="text"
                            value={inputValue}
                            size={20}
                            style={{
                                padding: '.5rem', outline: 0,
                                fontSize: '16px', color: "#131722", border: 0,
                            }}
                            onChange={handleInputChange} // Event handler for input change
                        />
                    </div>
                    <div style={{ height: '50vh', overflowY: 'scroll' }}>
                        {allCurrencies.map((item, index) => {
                            if (item.name.toLowerCase().includes(inputValue.toLowerCase())) return (
                                <Button key={item.name} type={'text'} onClick={() => {
                                    if (!watchList.includes(item)) {
                                        setWatchList([...watchList, item])
                                        saveData('watchList', [...watchList, item])
                                    }
                                }}
                                    style={{
                                        display: 'flex', alignItems: 'center', borderTop: index !== 0 ? '1px solid #F1F3FA' : 0,
                                        paddingRight: '1rem', paddingLeft: '1rem', paddingTop: '.5rem', paddingBottom: '.5rem', width: '100%'
                                    }}>
                                    <div style={{ width: '2rem' }}>
                                        <img style={{width: '2rem'}} src={`https://jksconsultants.com/api/currencies/images/${item.name}.svg`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src="/assets/placeholder.svg";
                                          }}
                                        
                                        ></img>
                                        {/* <img src={`https://jksconsultants.com/api/currencies/images/${item.name}.svg`}></img> */}
                                    </div>
                                    <div style={{ color: "#131722", fontSize: '16px' }}>{item.name}</div>
                                </Button>
                            )
                        })}
                    </div>
                </div>
            </Modal>
            {watchList.length > 0 && watchList.map(item => (<CurrencyRow key={item.name} item={item} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency}/>))}
            <div style={{position: 'absolute', borderBottom: '4px solid #E1E3EB', top: '38px',}}></div>
        </div>
    )
}