import React, { useEffect } from "react";
import {TradingView} from '../charting_library/charting_library.standalone.js'
import {useCurrentCurrency, useDesktopViewStatus, useOrderTabActive, useTokenStore} from '../store/index.js'
import { getGroup } from "../helper/getGroup.js";


export const TradingViewChart = ({ style}) => {
    const { token, setToken } = useTokenStore()
    const { currentCurrency, setCurrentCurrency } = useCurrentCurrency()
    const { orderTabActive, setOrderTabActive } = useOrderTabActive()


    useEffect(() => {
        const script = document.createElement("script");

        const updateChart = async () => {
            const group = await getGroup()
            script.type = "text/jsx";
            script.src = "../public/charting_library/charting_library.js"
            document.head.appendChild(script);

            const style = document.createElement("style");
            style.id = 'custom_loader'
            const css = `
        #custom_loader {  position: fixed; top: 0;left: 0;width: 100%;  height: 100%; background-color: rgba(0, 0, 0, 0.20); }"
    `;
            style.textContent = css;
            document.head.appendChild(style);


            const SupportedResolutions = ['1', '5', '15', '30', '60', '4H'];

            const AllSupportedSymbols = [];
            const GetHardcodedSymbols = () => {
                return [
                    { name: 'AUDUSD', ticker: 'AUDUSD', exchange: 'Forex', desc: 'AUD vs US Dollar', pricescale: 100000 },
                    { name: 'GBPJPY', ticker: 'GBPJPY', exchange: 'Forex', desc: 'GBP vs JPY', pricescale: 100000 },
                    { name: 'EURUSD', ticker: 'EURUSD', exchange: 'Forex', desc: 'EUR vs USD', pricescale: 100000 },
                    { name: 'GBPUSD', ticker: 'GBPUSD', exchange: 'Forex', desc: 'GBP vs USD', pricescale: 100000 },
                    { name: 'USDJPY', ticker: 'USDJPY', exchange: 'Forex', desc: 'USD vs JPY', pricescale: 1000 },
                    { name: 'CHFJPY', ticker: 'CHFJPY', exchange: 'Forex', desc: 'CHF vs JPY', pricescale: 1000 },
                    { name: 'EURJPY', ticker: 'EURJPY', exchange: 'Forex', desc: 'EUR vs JPY', pricescale: 1000 },
                    { name: 'US500', ticker: 'US500', exchange: 'Indices', desc: 'US500', pricescale: 10 },
                    { name: 'US30.spot', ticker: 'US30.spot', exchange: 'Indices', desc: 'US30.spot', pricescale: 10 },
                    { name: 'AUDCHF', ticker: 'AUDCHF', exchange: 'Forex', desc: 'AUD vs CHF', pricescale: 10000 },
                    { name: 'USDCHF', ticker: 'USDCHF', exchange: 'Forex', desc: 'USD vs CHF', pricescale: 10000 },
                    { name: 'XAUUSD', ticker: 'XAUUSD', exchange: 'Forex', desc: 'GOLD', pricescale: 10000 },
                    { name: 'EURCHF', ticker: 'EURCHF', exchange: 'Forex', desc: 'EUR vs CHF', pricescale: 10000 },
                    { name: 'AVXUSD', ticker: 'AVXUSD', exchange: 'Crypto', desc: ' Avalanche / US Dollar', pricescale: 100 },
                    { name: 'BTCUSD', ticker: 'BTCUSD', exchange: 'Crypto', desc: ' Bitcoin / US Dollar', pricescale: 100 },
                    { name: 'ETHUSD', ticker: 'ETHUSD', exchange: 'Crypto', desc: ' ETHEREUM / US Dollar', pricescale: 100 },
                ];
            }

            const GetSupportedSymbols = async () => {
                return new Promise(async (resolve, reject) => {
                    if (AllSupportedSymbols.length > 0) {
                        resolve(AllSupportedSymbols)
                    }
                    try {
                        let apiUrl = "https://jksconsultants.com/api/currencies/";
                        let apiResult = await fetch(apiUrl);
                        let jsonResult = await apiResult.json();
                        for (let a = 0; a < jsonResult.length; a++) {
                            let t = jsonResult[a];
                            AllSupportedSymbols.push({
                                name: t.name,
                                ticker: t.name,
                                exchange: t.type,
                                desc: t.name,
                                pricescale: Math.pow(10, 5)
                            });
                        }
                        resolve(AllSupportedSymbols);
                    } catch (e) {
                        console.error(e.message);
                        resolve(GetHardcodedSymbols());
                        //resolve([]);
                    }
                });
            }
            const GetIfSymbolSupported = async (symbolName) => {
                return new Promise(async (resolve, reject) => {
                    let allSymbols = await GetSupportedSymbols();
                    for (let a = 0; a < allSymbols.length; a++) {
                        let s = allSymbols[a];
                        if (s.name === symbolName) {
                            resolve(s);
                            return;
                        }
                    }
                    resolve(null);
                });
            }
            const GetSymbolInfoFromSymbol = (symbol) => {
                const symbolInfo = {
                    ticker: symbol.ticker,
                    name: symbol.name,
                    symbol: symbol.ticker,
                    description: symbol.desc,
                    exchange: symbol.exchange,
                    type: 'type',
                    minmov: 1,
                    pricescale: symbol.pricescale,
                    session: '24x7',
                    has_intraday: true,
                    has_no_volume: true,
                    supported_resolutions: SupportedResolutions,
                    data_status: 'streaming',
                    timezone: 'Etc/UTC',
                };
                return symbolInfo;
            }

            const CallHistoryApiUrl = (props) => {
                try{
                console.log("start CallHistoryApiUrl");
                const timeToAdd = 3 * 3600
                const fromTime = Math.floor(props.from / 1000) + timeToAdd
                const toTime = Math.floor(props.to / 1000) + timeToAdd;
                const baseURL =  "https://proxy.jksconsultants.com";
                let apiUrl = `${baseURL}/Chart?symbol=${props.symbol}&from=${fromTime}&to=${toTime}`
                console.log("apiurl", apiUrl)
                const myHeaders = new Headers();
                myHeaders.append("accept", "text/plain");
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow"
                };
                return fetch(apiUrl, requestOptions);
            }
                catch(error) {
                    console.log('callhistoryapi error', error)
                }
            }

            let selectedSymbol = null;
            let liveStreamInterval = null;
            let streamCallback = null;
            let lastHistoryBar = null;

            function getNextDailyBarTime(barTime) {
                const date = new Date(barTime);
                date.setDate(date.getDate() + 1);
                return date.getTime();
            }

            async function updateBars() {
                try{
                if (selectedSymbol === null) {
                    console.error("selectedSymbol is null");
                    return;
                }
                const symbol = selectedSymbol;
                const baseURL =  "https://proxy.jksconsultants.com";

                
                let apiUrl = `${baseURL}/Chart/ticklast?symbol=` + symbol + "&group=" + "";
                const myHeaders = new Headers();
                myHeaders.append("accept", "text/plain");
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", ` Bearer ${token}`);
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow"
                };
                let apiResult = await fetch(apiUrl, requestOptions);
                let jsonResultData = await apiResult.json();
                let jsonResult = jsonResultData.data;
                let tradePrice = jsonResult["ask"]
                let tradeTime = jsonResult["dateTime"] * 1000;
                if (lastHistoryBar != null) {
                    tradeTime = (lastHistoryBar.time + (1000 * 60));
                    const nextDailyBarTime = getNextDailyBarTime(parseInt(lastHistoryBar.time));
                    let bar;
                    if (tradeTime >= nextDailyBarTime) {
                        bar = {
                            time: nextDailyBarTime,
                            open: tradePrice,
                            high: tradePrice,
                            low: tradePrice,
                            close: tradePrice,

                        };
                    } else {
                        bar = {
                            ...lastHistoryBar,
                            high: Math.max(lastHistoryBar.high, tradePrice),
                            low: Math.min(lastHistoryBar.low, tradePrice),
                            close: tradePrice,
                        };
                    }
                    lastHistoryBar = bar;
                    if (streamCallback != null) {
                        streamCallback(bar);
                    }
                }}
                catch(error){
                    console.log('updatebars error', error)
                }
            }

            const configurationData = {
                supported_resolutions: SupportedResolutions,
                exchanges: [
                    { value: 'Currency Exchange', name: 'Currency Exchange', desc: 'Currency Exchange' },
                ],
                symbols_types: [
                    { name: 'currency', value: 'currency' }
                ]
            };

            let currentLineOrders = [];
            let CurrentChartSymbol = "";


            async function drawTheLineOnChart() {

                console.log("drawTheLineOnChart: " + CurrentChartSymbol);
                try {
                    const login = 6666;
                    const baseURL =  "https://proxy.jksconsultants.com";

                    
                    let apiUrl = `${baseURL}/Order/open?login=` + login;

                    const myHeaders = new Headers();
                    myHeaders.append("accept", "text/plain");
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", ` Bearer ${token}`);
                    const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow"
                    };
                    let apiResult = await fetch(apiUrl, requestOptions);
                    let jsonResult = await apiResult.json();
                    let trades = jsonResult.data.position;
                    if (trades === null || trades === undefined) {
                        console.error("No trades data available.");
                        return; // Exit the function or handle this scenario appropriately.
                    }
                    for (let a = 0; a < currentLineOrders.length; a++) {
                        currentLineOrders[a].remove();
                    }
                    for (let a = 0; a < trades.length; a++) {
                        let trade = trades[a];
                        //trade.symbol=CurrentChartSymbol; //for testing
                        if (trade.symbol === CurrentChartSymbol) {
                            let mult = "1";
                            for (let d = 1; d <= trade.digits; d++) {
                                mult += "0";
                            }
                            let multiplier = parseInt(mult);
                            let TPprice = Math.round((trade.priceTP + Number.EPSILON) * multiplier) / multiplier;
                            let SLprice = Math.round((trade.priceSL + Number.EPSILON) * multiplier) / multiplier;
                            let price = Math.round((trade.priceOpen + Number.EPSILON) * multiplier) / multiplier;
                            let action;
                            if (trade.action === 0) { action = "Buy"; }
                            else if (trade.action === 1) { action = "Sell"; }
                            let txt = action + "Position # " + trade.position + " " + trade.volume / 10000;

                            let order = window.tvWidget.chart().createOrderLine().setText(txt)
                                .setLineLength(3)
                                .setLineStyle(0)
                                .setQuantity(trade.volume / 10000)
                            order.setPrice(price);

                            currentLineOrders.push(order);

                            let txtTP = "TP: Position # " + trade.position;
                            let orderTP = window.tvWidget.chart().createOrderLine().setText(txtTP)
                                .setLineLength(3)
                                .setLineStyle(2)
                                .setLineColor('red')
                                .setQuantity(trade.volume / 10000)

                            orderTP.setPrice(TPprice);

                            currentLineOrders.push(orderTP);

                            let txtSL = "SL: Position # " + trade.position;
                            let orderSL = window.tvWidget.chart().createOrderLine().setText(txtSL)
                                .setLineLength(3)
                                .setLineStyle(2)
                                .setLineColor('red')
                                .setQuantity(trade.volume / 10000)

                            orderSL.setPrice(SLprice);

                            currentLineOrders.push(orderSL);



                        }
                    }
                } catch (e) {
                    console.error('drawline error', e.message);
                }
            }


            const CustomDatafeed = {
                onReady: (callback) => {
                    setTimeout(() => callback(configurationData));
                },
                searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
                    setTimeout(async () => {
                        let allSymbols = await GetSupportedSymbols();
                        let newSymbols = [];
                        for (let a = 0; a < allSymbols.length; a++) {
                            let s = allSymbols[a];
                            if (userInput === "" || s.name.toUpperCase().indexOf(userInput.toUpperCase()) === 0) {
                                let sInfo = GetSymbolInfoFromSymbol(s);
                                newSymbols.push(sInfo);
                            }
                        }
                        let uniqueNames = {};
                        let uniqueArray = newSymbols.filter(obj => {
                            if (!uniqueNames[obj.name]) {
                                uniqueNames[obj.name] = true;
                                return true;
                            }
                            return false;
                        });
                        onResultReadyCallback(uniqueArray);
                    }, 50)
                },
                resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
                    setCurrentCurrency(symbolName)
                    setTimeout(async () => {
                        let symbol = await GetIfSymbolSupported(symbolName);
                        let symbolInfo = null;
                        if (symbol != null) {
                            symbolInfo = GetSymbolInfoFromSymbol(symbol);
                        }
                        onSymbolResolvedCallback(symbolInfo);
                    }, 0);
                },
                getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
                    console.log("getbars")
                    if (periodParams.firstDataRequest) {
                        let loaderElem = document.getElementById("custom_loader");
                        loaderElem.style.display = "block";
                        selectedSymbol = symbolInfo.ticker;
                        if (liveStreamInterval != null) {
                            clearInterval(liveStreamInterval);
                        }
                        let current_timestamp = new Date().getTime();
                        let minutes_behind = 120;
                        console.log('resolution', resolution)
                        let start_timestamp = current_timestamp - (1000 * 3 * 60 * minutes_behind); //go X minutes behind
                        let props = { symbol: selectedSymbol, resolution: resolution, from: start_timestamp, to: current_timestamp };
                        let jsonResult = null;
                        let apiCallCount = 0;
                        while (true) {
                            try {
                                let apiResult = await CallHistoryApiUrl(props);
                                console.log("end callhistoryapiurl")
                                const jsonResultData = await apiResult.json();
                                jsonResult = jsonResultData.data;
                                console.log(jsonResult)

                                break;
                            } catch (e) {
                                // console.error(e.message);
                            }
                            apiCallCount++;
                            console.log("api call count", apiCallCount)
                            if (apiCallCount === 5) {
                                break;
                            }
                        }
                        console.log("end of while")
                        if (jsonResult === null) {
                            loaderElem.style.display = "none";
                            onHistoryCallback([], { noData: true });
                        }
                        let dataToReturn = [];
                        for (let a = 0; a < jsonResult.length; a++) {
                            let r = jsonResult[a];
                            r.date_time = r.dateTime;
                            r.time = r.dateTime * 1000;
                            dataToReturn.push(r);
                        }
                        if (dataToReturn.length > 0) {
                            lastHistoryBar = dataToReturn[dataToReturn.length - 1];
                        } else {
                            lastHistoryBar = null;
                        }
                        console.log("Onhistorycallback")
                        onHistoryCallback(dataToReturn, { noData: false }); //return the recently constructed buffered data
                        if (liveStreamInterval != null) {
                            clearInterval(liveStreamInterval);
                        }
                        // liveStreamInterval = setInterval(updateBars, 10 * 10);
                        liveStreamInterval = setInterval(updateBars, 5000 * 10);
                        loaderElem.style.display = "none";
                        console.log('drawtheline')
                        drawTheLineOnChart()
                    } else {
                        onHistoryCallback([], { noData: true });
                    }
                },
                subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
                    streamCallback = onRealtimeCallback;
                },
                unsubscribeBars: (subscriberUID) => {
                    console.log("[unsubscribeBars]: " + subscriberUID);
                },
            };



            window.tvWidget = new TradingView.widget({
                symbol: currentCurrency, // default symbol
                interval: "5", // default interval
                height: '100%',
                width: '100%',
                // fullscreen: true, // displays the chart in the fullscreen mode
                container: "tv_chart_container",
                datafeed: CustomDatafeed,
                context_menu: {
                    items_processor: (defaultItems, actionFactory) => {
                      const myNewItem = actionFactory.createAction({
                        label: "New Order",
                        
                        onExecute: () => {
                          setOrderTabActive(true)
                        },
                      });
                      const items = [myNewItem, ...defaultItems.slice()];
                      return items;
                    },
                  },
                library_path: "/charting_library/",
                // overrides: {
                //     'paneProperties.background': '#00101F',
                //     "paneProperties.vertGridProperties.color": '#011B33',
                //     "paneProperties.vertGridProperties.style": '1',
                //     "paneProperties.horzGridProperties.color": '#011B33',
                //     "paneProperties.horzGridProperties.style": '1',
                //     "scalesProperties.textColor": '#98989A'
                // },
                disabled_features: [

                    // "control_bar",
                    // "timeframes_toolbar",
                    // "header_screenshot",
                    // "header_fullscreen_button",
                    // "header_settings",
                    // "header_compare",
                    // "header_widget",
                    // "left_toolbar",
                    // "legend_widget"
                ]
            });

        }

        if (token) updateChart()

        return () => script.remove();


    }, [currentCurrency, token]);

    const {desktopView} = useDesktopViewStatus()

    
    if (desktopView) return (<div  style={{height: '100%'}} id="tv_chart_container"></div>)
    else return (<div  style={{height: '90vh', width: '100%'}} id="tv_chart_container"></div>)
    
}