import { TradingViewChart } from './components/TradingViewChart';
import { WatchList } from './components/WatchList';
import { useAllCurrenciesStore, useAuthStore, useDesktopViewStatus, useOrderTabActive, useOrderTabWidth, useSession, useTokenStore, useWatchListStore, useWindowWidth } from './store';
import { createRef, useEffect, useState } from 'react';
import { getData, removeData, saveData } from './store/localStorgae';
import styles from './App.module.css'
import { OrderTab } from './components/OrderTab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Input, Modal } from 'antd';
import Draggable from 'react-draggable';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getGroup } from './helper/getGroup';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { getNewToken } from './helper/getNewToken';
import { Footer } from 'antd/es/layout/layout';
import { MdOutlineCandlestickChart } from "react-icons/md";
import { MdOutlineAlignHorizontalLeft } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { Orders } from './components/Orders';




function App() {
  const { token, setToken } = useTokenStore()
  const { orderTabActive, setOrderTabActive } = useOrderTabActive()
  const { sessionId, setSessionId } = useSession()
  const [password, setPassword] = useState()
  const [username, setUsername] = useState()

  const { watchList, setWatchList } = useWatchListStore()
  const { allCurrencies, setAllCurrencies } = useAllCurrenciesStore()
  const [modalOpen, setModalOpen] = useState(false)
  const { isLoggedIn, login, logout } = useAuthStore()
  const handleModalCancel = () => {
    setModalOpen(false)
  }
  const dragRef = createRef();




  useEffect(() => {
    const watchList = getData('watchList')
    if (watchList) setWatchList(watchList)
    const allCurrencies = getData('allCurrencies')
    if (allCurrencies) setAllCurrencies(allCurrencies)
  }, [])

  const handleSignIn = async (username, password) => {
    console.log("handle signin")
    let data = JSON.stringify({
      username: username,
      password: password
    });
    const baseURL = "https://proxy.jksconsultants.com";
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/login`,
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios.request(config)
      .then(async (response) => {
        if (response.data.success === true) {
          toast(response.data.message)
          const token = response.data.data;
          saveData('token', token)
          setToken(token)
          const loginData = {
            username: username,
            password: password
          }
          saveData('loginData', loginData)
          saveData('serverName', "")
          saveData('sessionId', username)
          getGroup(username, token)
          setSessionId(username)

          await login()
          handleModalCancel()
        } else {
          toast(response.data.message)

          // toast.show(response.data.message, {
          //   type: "danger",
          //   placement: "bottom",
          //   duration: 3000,
          //   offset: 30,
          //   animationType: "slide-in",
          // })
        }
      })
      .catch((error) => {
        toast(error.message)

        // toast.show(error.message, {
        //   type: "danger",
        //   placement: "bottom",
        //   duration: 3000,
        //   offset: 30,
        //   animationType: "slide-in",
        // })
      })
  }

  const handleSubmit = async () => {

    await handleSignIn(username, password)
  }


  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [tradeTabHeight, setTradeTabHeight] = useState(window.innerHeight / 2)
  const [chartWidth, setChartWidth] = useState(window.innerWidth / 2)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { screenWidth, setScreenWidth } = useWindowWidth();

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    const sessionId = getData('sessionId')
    if (sessionId) setSessionId(sessionId)
  }, [])

  const { desktopView, setDesktopView } = useDesktopViewStatus()

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      setScreenWidth(windowWidth);
      setDesktopView(windowWidth > 600)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const loginData = getData('loginData')
    if (loginData) login()

  }, [])

  const handleLogout = () => {
    setActiveTab("chart")
    removeData('loginData')
    logout()
  }





  useEffect(() => {
    const updateToken = async () => {
      console.log("will call getnewtoken")
      const token = await getNewToken()
      setToken(token)
      saveData('token', token)
      console.log("token updated")
    }

    updateToken();
    const intevalTime = 6 * 60 * 60 * 1000;
    setInterval(updateToken, intevalTime)
  }, [])


  const [chartWidthPercent, setChartWidthPercent] = useState(75)

  const [activeTab, setActiveTab] = useState("chart")

  const getWatchListMinSize = () => {
    if (desktopView) {
      console.log("watchlist min size 10")
      return 10
    }
    if (!desktopView && activeTab === "watchList") {
      console.log("watchlist min size 100")
      return 100
    }
    if (!desktopView && activeTab !== "orders") {
      console.log("watchlist min size 0")
      return 0
    }
  }

  const getWatchListMaxSize = () => {
    if (desktopView) {
      console.log("watchlist max size 40")
      return 40
    }
    if (!desktopView && activeTab === "watchList") {
      console.log("watchlist max size 100")
      return 100
    }
    if (!desktopView && activeTab !== "watchList") {
      console.log("watchlist max size 0")
      return 0
    }
  }

  const { orderTabWidth, setOrderTabWidth } = useOrderTabWidth()

  console.log('active tab', activeTab)

  return (
    <div style={{ height: '100vh', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: "hidden" }}>
      {desktopView ?
        // <PanelGroup direction="horizontal" >
        //   <Panel id="chartAndOrders" defaultSize={75} minSize={30} order={1} onResize={percent => { setChartWidth(screenWidth * percent / 100); setChartWidthPercent(percent) }}>
        //     <PanelGroup direction='vertical'>
        //       <Panel defaultSize={60} minSize={30} ><TradingViewChart />  </Panel>
        //       <PanelResizeHandle style={{ backgroundColor: '#E1E3EB', height: '5px' }} />
        //       <Panel defaultSize={40} minSize={30} onResize={percent => setTradeTabHeight(windowHeight * percent / 100)}>{isLoggedIn ? <Orders height={tradeTabHeight} width={chartWidth} /> :
        //         (<div style={{ height: tradeTabHeight, display: 'flex', }}>
        //           <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
        //             <div style={{ width: '20rem', height: '10rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: 'rgb(229 235 238)', borderRadius: '1rem' }}>
        //               <div style={{ paddingBottom: ".5rem", fontWeight: 700, fontSize: '2rem' }}>FinPro<span style={{ fontSize: '2.5rem' }}>X</span></div>
        //               <Button onClick={() => setModalOpen(true)} size='large' style={{ backgroundColor: 'black', color: 'white', fontWeight: 700 }}>Login</Button>
        //             </div>
        //           </div>
        //         </div>)}</Panel>
        //     </PanelGroup>
        //   </Panel>
        //   {orderTabActive && isLoggedIn &&
        //     <>
        //       <PanelResizeHandle style={{ backgroundColor: '#E1E3EB', width: '5px', marginTop: '38px' }} />
        //       <Panel id="orderTab" minSize={25} maxSize={30} order={2}><OrderTab desktopView={desktopView} /></Panel>
        //     </>
        //   }
        //   <PanelResizeHandle style={{ backgroundColor: '#E1E3EB', width: '5px', marginTop: '38px' }} />
        //   <Panel id="watchlist" defaultSize={25} minSize={getWatchListMinSize()} maxSize={getWatchListMaxSize()} order={3}> <WatchList /> </Panel>
        // </PanelGroup> 
        <PanelGroup direction="vertical" >
          <Panel id="chartAndWatchlist" defaultSize={60} minSize={30} order={1} >
            <PanelGroup direction='horizontal'>
              <Panel id="chart" defaultSize={60} minSize={30} onResize={percent => { setChartWidth(screenWidth * percent / 100); setChartWidthPercent(percent) }}><TradingViewChart />  </Panel>
              {orderTabActive && isLoggedIn &&
                <>
                  <PanelResizeHandle style={{ backgroundColor: '#E1E3EB', width: '5px', marginTop: '38px' }} />
                  <Panel id="orderTab" minSize={25} maxSize={30} onResize={percent => setOrderTabWidth(percent)}><OrderTab desktopView={desktopView} /></Panel>
                </>
              }
              <PanelResizeHandle style={{ backgroundColor: '#E1E3EB', width: '5px', marginTop: '38px' }} />
              <Panel id="watchlist" defaultSize={25} minSize={getWatchListMinSize()} maxSize={getWatchListMaxSize()} order={4}> <WatchList /> </Panel>
            </PanelGroup>
          </Panel>

          <PanelResizeHandle style={{ backgroundColor: '#E1E3EB', height: '5px', width: '100%', }} />
          <Panel id="orders" order={2} defaultSize={40} minSize={30} onResize={percent => setTradeTabHeight(windowHeight * percent / 100)}>{isLoggedIn ? <Orders height={tradeTabHeight} width={chartWidth} /> :
            (<div style={{ height: tradeTabHeight, display: 'flex', }}>
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '20rem', height: '10rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: 'rgb(229 235 238)', borderRadius: '1rem' }}>
                  <div style={{ paddingBottom: ".5rem", fontWeight: 700, fontSize: '2rem' }}>FinPro<span style={{ fontSize: '2.5rem' }}>X</span></div>
                  <Button onClick={() => setModalOpen(true)} size='large' style={{ backgroundColor: 'black', color: 'white', fontWeight: 700 }}>Login</Button>
                </div>
              </div>
            </div>)}</Panel>
        </PanelGroup>

        :


        <div style={{ display: 'flex', height: '100vh', width: '100%', position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, overflow: 'hidden' }}>
          <div style={{ display: activeTab === "chart" ? "flex" : "none", width: '100%' }}><TradingViewChart /></div>
          <div style={{ display: activeTab === "orders" ? "flex" : "none", width: '100%', height: '90vh', position: 'absolute', top: 0, left: 0, right: 0, bottom: '10vh' }}><Orders height='90vh' width="100%" /></div>
          <div style={{ display: activeTab === "watchList" ? "flex" : "none", width: '100%' }}><WatchList /></div>
          <div style={{
            position: 'absolute',
            bottom: 0,
            zIndex: 1,
            height: '10vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            background: '#EDEBE5'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',

            }}>

              <Button type="text" onClick={() => setActiveTab('chart')}>
                <div style={{ color: activeTab === 'chart' ? 'blue' : 'black', fontWeight: 'bold' }}>
                  <MdOutlineCandlestickChart size={20} />
                  <div>Chart</div>
                </div>
              </Button>
              {isLoggedIn && <Button type="text" onClick={() => setActiveTab('orders')}>
                <div style={{ color: activeTab === 'orders' ? 'blue' : 'black', fontWeight: 'bold' }}>
                  <MdOutlineAlignHorizontalLeft size={20} />
                  <div>Orders</div>
                </div>
              </Button>}
              <Button type="text" onClick={() => setActiveTab('watchList')}>
                <div style={{ color: activeTab === 'watchList' ? 'blue' : 'black', fontWeight: 'bold' }}>
                  <MdOutlineAlignHorizontalLeft size={20} />
                  <div>WatchList</div>
                </div>
              </Button>
              {isLoggedIn ? <Button type="text" onClick={() => handleLogout()}>
                <div style={{ color: 'red', fontWeight: 'bold' }}>
                  <IoIosLogOut size={20} />
                  <div>Logout</div>
                </div>
              </Button> :
                <Button type="text" onClick={() => setModalOpen(true)}>
                  <div style={{ color: 'blue', fontWeight: 'bold' }}>
                    <IoIosLogOut size={20} />
                    <div>Login</div>
                  </div>
                </Button>}
            </div>
          </div>
          {isLoggedIn && <Modal
            open={orderTabActive}
            mask={false}
            maskClosable={true}
            keyboard={false}
            wrapClassName="aaa"
            width={screenWidth - 20}
            className={styles.antd}
            onCancel={handleModalCancel}
            closable={false}
            style={{
              position: 'fixed',
              left: 10,
              padding: 0

            }}
            footer={false}
            modalRender={modal => {
              if (desktopView) {
                return (<Draggable
                >
                  <div aa="2" ref={dragRef} >
                    {modal}
                  </div>
                </Draggable>)
              }
              else return modal
            }}
          >
            <OrderTab desktopView={desktopView} />
          </Modal>}
        </div>

      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoggedIn && desktopView && <div style={{ position: 'absolute', top: 0, left: (chartWidthPercent / 100 * screenWidth) + 10, height: '38px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '1rem' }}>
        <Button className={styles.newOrderBtn} style={{ background: "#2862FF", color: 'white', fontWeight: 'bold' }} onClick={() => setOrderTabActive(true)}>New Order</Button>
      </div>}
      {isLoggedIn && desktopView && <div style={{ position: 'absolute', top: 0, right: 0, height: '38px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '.5rem', }}><Button
        onClick={() => handleLogout()} style={{ color: "#F23645", fontWeight: 600, }}
        className={styles.logoutBtn}>Logout</Button></div>}
      <Modal
        open={modalOpen}
        mask={false}
        maskClosable={true}
        keyboard={false}
        wrapClassName="aaa"
        width={desktopView ? 500 : screenWidth - 20}
        className={styles.antd}
        onCancel={handleModalCancel}

        style={{
          position: 'fixed',
          left: desktopView ? (document.body.clientWidth - 500) / 2 : 10,
          padding: 0

        }}
        footer={false}
        modalRender={modal => {
          if (desktopView) {
            return (<Draggable
            >
              <div aa="2" ref={dragRef} >
                {modal}
              </div>
            </Draggable>)
          }
          else return modal
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            <div style={{ textAlign: 'center', fontSize: 'larger', fontWeight: 'bolder', marginBottom: '1rem' }}>Sign in</div>
            <Input onChange={(e) => setUsername(e.target.value)} size="large" placeholder="Username" prefix={<UserOutlined />} />
            <Input.Password onChange={(e) => { setPassword(e.target.value); console.log(e.target.value) }} style={{ marginTop: '.5rem' }} size="large" placeholder="Password" prefix={<KeyOutlined />} />
            <Button onClick={handleSubmit} style={{ marginTop: '1rem' }}>Login</Button>
          </div>
        </div>
      </Modal>


    </div>
  )
}


export default App;
