import { Button, Checkbox, Tabs } from "antd"
import { useCallback, useEffect, useState } from "react"
import styles from './OrderTab.module.css'
import { formatStringDecimal } from "../utilities/formatStringDecimal"
import { getDecimalPoint } from "../utilities/getDecimalPoint"
import { getGroup } from "../helper/getGroup"
import axios from "axios"
import { RxCross1 } from "react-icons/rx";
import { formatDecimal } from "../utilities/formatDecimal"
import { toast } from 'react-toastify';
import { useCurrentCurrency, useOrderTabActive, useOrderTabWidth, useTokenStore, useWindowWidth } from "../store"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";

export const OrderTab = ({ desktopView }) => {
  const { token, setToken } = useTokenStore()
  const [selectedTab, setSelectedTab] = useState('market')
  const [volume, setVolume] = useState("0.1")
  const { currentCurrency } = useCurrentCurrency()


  const removeInitilaZeroFromNumber = (string) => {
    if (string.slice(0, 2) === "0.") return string
    if (string.length > 1 && string.slice(0, 1) === "0") return string.slice(1)
    return string
  }

  const onChangeVolume = (event) => {
    const newVolume = event.target.value
    if (!isNaN(newVolume)) {
      if (newVolume === "") setVolume("0")
      else {
        if (parseFloat(newVolume) > 100) setVolume("100")
        else {
          const formattedVolume = removeInitilaZeroFromNumber(newVolume)
          setVolume(formatStringDecimal(formattedVolume, 2))
        }
      }
    }
  }



  const [priceButtonValue, setPriceButtonValue] = useState("0.000")
  const [priceButtonActive, setPriceButtonActive] = useState(false)


  const onChangePrice = (e) => {
    const number = e.target.value;
    console.log(number, "number", !isNaN(number))
    if (!isNaN(number)) {
      console.log("true 2")
      if (number === "") {
        console.log("true 3")

        setPriceButtonValue("0")
      }
      else {
        console.log("true 4")

        const formattedNumber = removeInitilaZeroFromNumber(number)
        console.log("formattedNumber", formattedNumber)
        const formattedDecimal = formatStringDecimal(formattedNumber, decimalPoint)
        console.log("formattedDecimal", formattedDecimal)

        setPriceButtonValue(formattedDecimal)
        console.log("pricebuttonvalue", priceButtonValue)
      }
    }
    else return
  }


  const [decimalPoint, setDecimalPoint] = useState(5)

  const [bid, setBid] = useState(0)
  const [bidValueChanged, setBidValueChanged] = useState(false)
  const [bidActive, setBidActive] = useState(false)
  const [ask, setAsk] = useState(0)
  const [askValueChanged, setAskValueChanged] = useState(false)
  const [askActive, setAskActive] = useState(false)
  const [activeButton, setActiveButton] = useState('buy')

  useEffect(() => {

    const fetchData = async () => {
      const group = await getGroup()
      const baseURL = "https://proxy.jksconsultants.com";
      const API_URL = `${baseURL}/Chart/ticklast?symbol=${currentCurrency}&group=${group}`;

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: API_URL,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/plain',
          Authorization: `Bearer ${token}`,
        }
      };
      await axios
        .request(config)
        .then((response) => {
          console.log('fetched lasttick data')
          const data = response.data.data;
          if (!bidValueChanged) setBid(data.bid)
          if (!askValueChanged) setAsk(data.ask)
        })
        .catch((error) => {
          console.error("[Market] Error fetching initial data: ", error);
        });
    }
    if (token) fetchData();
  }, [currentCurrency, token]);

  const getOrderStatus = async (id, token) => {
    const baseURL = "https://proxy.jksconsultants.com";
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/Order/status?req=${id}`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    let eventMessages = '';
    let orderSuccessful;
    let orderMessage;
    let orderNumber;
    await axios.request(config)
      .then((response) => {
        const events = response.data.data[0].events;
        orderNumber = response.data.data[0].orderNumber;
        events.map(item => {
          eventMessages += item.message
        }
        )
      })
      .catch((error) => {
        console.error(error);
      });

    if (eventMessages.includes('MT_RET_REQUEST_DONE')) {
      orderSuccessful = true;
      orderMessage = `Order #${orderNumber} successful`
    }
    else if (eventMessages.includes('MT_RET_REQUEST_NO_MONEY')) {
      orderSuccessful = false
      orderMessage = `Not enough money`
    }
    else if (eventMessages.includes('MT_RET_REQUEST_INVALID_PRICE')) {
      orderSuccessful = false
      orderMessage = `Invalid price`
    }
    else {
      orderSuccessful = false
      orderMessage = `Order failed`
    }

    const status = {
      orderSuccessful: orderSuccessful, orderMessage: orderMessage
    }
    return status
  }

  const handleClickBuySellButton = async (type) => {
    let data = JSON.stringify({
      login: 6666,
      symbol: currentCurrency,
      type: activeButton === "sell" ? 1 : 0,
      priceSL: bidActive ? bid : 0,
      priceTP: askActive ? ask : 0,
      volumeInitial: volume * 10000,
      comment: "test",
    });

    const baseURL = "https://proxy.jksconsultants.com";


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}/Order/create`,
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    await axios
      .request(config)
      .then(async (response) => {
        if (response.data.success) {
          const status = await getOrderStatus(response.data.data, token)
          if (status.orderSuccessful) {
            toast(status.orderMessage)
            // toast.show(status.orderMessage, {
            //   type: "success",
            //   placement: "bottom",
            //   duration: 1000,
            //   offset: 30,
            //   animationType: "slide-in",
            // })

          }
          else {
            toast(status.orderMessage)
            // toast.show(status.orderMessage, {
            //   type: "warning",
            //   placement: "bottom",
            //   duration: 1000,
            //   offset: 30,
            //   animationType: "slide-in",
            // })
          }
        }

        else {
          toast(`Error ${type === 0 ? "selling" : "buying"}`)
          //   toast.show(`Error ${type === 0 ? "selling" : "buying"}`, {
          //     type: "warning",
          //     placement: "bottom",
          //     duration: 1000,
          //     offset: 30,
          //     animationType: "slide-in",
          //   })
        }
      }
      )
      .catch((error) => {
        console.error(`[Market] handleclick neworder error: ${JSON.stringify(error)}`);
      });
  }


  const handleClickPlace = async () => {
    const getType = () => {
      if (selectedTab === "buyLimit") return 2
      if (selectedTab === "sellLimit") return 3
      if (selectedTab === "buyStop") return 4
      if (selectedTab === "sellStop") return 5
    }

    let data = JSON.stringify({
      login: 6666,
      symbol: currentCurrency,
      type: getType(),
      priceSL: bidActive ? parseFloat(bid) : 0,
      priceTP: askActive ? parseFloat(ask) : 0,
      volumeInitial: volume * 10000,
      comment: "test",
      typeFill: 2,
      priceOrder: parseFloat(priceButtonValue)
    });

    const baseURL = "https://proxy.jksconsultants.com";
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}/Order/create`,
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    await axios
      .request(config)
      .then(async (response) => {
        if (response.data.success) {
          const status = await getOrderStatus(response.data.data, token)
          if (status.orderSuccessful) {
            toast(status.orderMessage)
            // toast.show(status.orderMessage, {
            //   type: "success",
            //   placement: "bottom",
            //   duration: 1000,
            //   offset: 30,
            //   animationType: "slide-in",
            // })

          }
          else {
            toast(status.orderMessage)
            // toast.show(status.orderMessage, {
            //   type: "warning",
            //   placement: "bottom",
            //   duration: 1000,
            //   offset: 30,
            //   animationType: "slide-in",
            // })
          }
        }
        else {
          const type = getType()
          toast(`Error ${type === 0 ? "selling" : "buying"}`)
          //   toast.show(`Error ${type === 0 ? "selling" : "buying"}`, {
          //     type: "warning",
          //     placement: "bottom",
          //     duration: 1000,
          //     offset: 30,
          //     animationType: "slide-in",
          //   })
        }
      }
      )
      .catch((error) => {
        console.error(`[Market] handleclick neworder error: ${JSON.stringify(error)}`);
      });
  }


  //   useEffect(

  //     useCallback(() => {

  //       const decimalPoint = getDecimalPoint(currentCurrency, allCurrencies)
  //       setDecimalPoint(decimalPoint)

  //     }, [])
  //   );

  const items = [
    {
      label: `Market Execution`,
      key: "market",
    },
    {
      label: `Buy Stop`,
      key: "buyStop",

    },
    {
      label: `Sell Stop`,
      key: "sellStop",
    },
    {
      label: `Buy Limit`,
      key: "buyLimit",
    },
    {
      label: `Sell Limit`,
      key: "sellLimit",
    },
  ]


  const inputWidth = {
    transition: 'all 0.3s',
    width: `${priceButtonValue.length + 2}ch`,
    boxSizing: 'border-box',
  };

  const onChangeNumber = (number, type) => {
    if (type == "sl") {
      if (!isNaN(number)) {
        if (number === "") setBid("0")
        else {
          const formattedNumber = removeInitilaZeroFromNumber(number)
          setBid(formatStringDecimal(formattedNumber, decimalPoint))
        }
      }
    }
    else {
      if (!isNaN(number)) {
        if (number === "") setAsk("0")
        else {
          const formattedNumber = removeInitilaZeroFromNumber(number)
          setAsk(formatStringDecimal(formattedNumber, decimalPoint))
        }
      }
    }
  }

  const WS_URL = `wss://copyapi.jksconsultants.com/ws`
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL, {
    protocols: ['client', token],
    shouldReconnect: (closeEvent) => true,
    onError: (error) => console.error(`[Forex] websocket error: ${JSON.stringify(error)}`)
  },
    Boolean(token)
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    if (connectionStatus == 'Open') {
      // [needs to be fixed]
      // works only when sendJsonMessage is called twice
      // shows same behaviour on Web socket clients
      // probably a server issue
      sendJsonMessage({
        item: "candle",
        command: "subscribe",
        data: currentCurrency
      })
      sendJsonMessage({
        item: "candle",
        command: "subscribe",
        data: currentCurrency
      })
    }
  }, [connectionStatus, currentCurrency])


  //handle price

  const getPriceChange = () => {
    const decimal = parseInt(decimalPoint)
    if (decimal === 5) {
      return 0.00020
    }
    else return .10

  }

  const increasePrice = () => {
    if (!priceButtonActive) {
      setPriceButtonActive(true)
      const ask = lastJsonMessage ? lastJsonMessage.data.Ask : 0
      setPriceButtonValue(formatDecimal(ask, decimalPoint).toString())
    }
    else {
      setPriceButtonValue(formatDecimal((parseFloat(priceButtonValue) + getPriceChange()), decimalPoint).toString())
    }
  }

  const decreasePrice = () => {
    if (!priceButtonActive) {
      setPriceButtonActive(true)
      const bid = lastJsonMessage ? lastJsonMessage.data.Bid : 0
      setPriceButtonValue(formatDecimal(bid, decimalPoint).toString())
    }
    else {
      const newPrice = (parseFloat(priceButtonValue) - getPriceChange())
      if (newPrice < 0) setPriceButtonValue("0.000")
      else setPriceButtonValue(formatDecimal(newPrice, decimalPoint).toString())
    }
  }


  const { orderTabActive, setOrderTabActive } = useOrderTabActive()
  console.log("price button value", priceButtonValue)

  const {orderTabWidth, setOrderTabWidth} = useOrderTabWidth()
  const { screenWidth, setScreenWidth } = useWindowWidth();



  return (
    <div style={{ backgroundColor: 'white', marginTop: desktopView ? '38px' : 0, borderTop: desktopView ? '4px solid #E1E3EB' : 0, }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '1rem', paddingTop: desktopView ? '.5rem' : 0, fontSize: '14px', fontWeight: 600 }}>
        <div>Symbol: {currentCurrency}</div>
        <Button onClick={() => setOrderTabActive(false)} type="text"><RxCross1 size={20} /></Button>
      </div>
      <div style={{ width: '100%', borderBottom: "1px solid #F3F5FC" }}></div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem', }}>

        {selectedTab === "market" ?
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '2.5rem', color: 'white', marginBottom: '2rem' }}>
            <div onClick={() => { setActiveButton("sell") }} className={activeButton === "sell" ? styles.sellActive : styles.sell}>
              <div style={{ fontWeight: 590 }}>
                Sell
              </div>
              <div>
                {lastJsonMessage ? lastJsonMessage.data.Bid : formatDecimal(bid, 5)}
              </div>
            </div>
            <div onClick={() => { setActiveButton("buy") }} className={activeButton === "buy" ? styles.buyActive : styles.buy}>
              <div style={{ fontWeight: 590 }}>

                Buy
              </div>
              <div>
                {lastJsonMessage ? lastJsonMessage.data.Ask : formatDecimal(ask, 5)}

              </div>
            </div>
          </div> :
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '2.5rem', color: 'white', marginBottom: '2rem' }}>
            <div style={{ width: '100%', background: '#F23645', height: '100%', borderRadius: '1rem', padding: '.5rem', border: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button type="text" onClick={decreasePrice}><FaMinus color="white" /></Button>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>Price:</div>
                <input style={inputWidth} onChange={onChangePrice} value={priceButtonValue} className={styles.priceInput} />
              </div>
              <Button type="text" onClick={increasePrice}><FaPlus color="white" /></Button>
            </div>
          </div>
        }

        <Tabs style={{ paddingLeft: '1rem', width: desktopView ? ((orderTabWidth/100 * screenWidth)-50): '300px' }}
          tabPosition="top"
          defaultActiveKey="1"
          items={items}
          onChange={(key) => setSelectedTab(key)}
        />
        <div style={{ display: 'flex', flexDirection: 'column', }}>
          <div style={{
            color: "#6a6d78",
            display: "flex",
            fontSize: "14px",
            lineHeight: "22px"
          }}>Volume</div>
          <input className={styles.input} value={volume} onChange={onChangeVolume} />
        </div>
      </div>
      <div style={{ display: 'flex', borderBottom: '2px solid #DDDEE4', marginTop: '1rem' }}>
      </div>
      <div className={styles.slTpContainerRoot}>
        <div className={styles.slTpContainer}>
          <Checkbox checked={bidActive} onChange={(e) => setBidActive(e.target.checked)}>
            Stop Loss
          </Checkbox>
          <Checkbox checked={askActive} onChange={(e) => setAskActive(e.target.checked)}>
            Take Profit
          </Checkbox>
          <div>
            <input onFocus={() => setBidActive(true)} className={bidActive ? styles.slTpValue : styles.slTpValueInActive} value={bid} onChange={(e) => onChangeNumber(e.target.value, 'sl')} />
          </div>
          <div>
            <input onFocus={() => setAskActive(true)} className={askActive ? styles.slTpValue : styles.slTpValueInActive} value={ask} onChange={(e) => onChangeNumber(e.target.value, 'tp')} />
          </div>
        </div>
        <div>


        </div>
      </div>

      <div style={{ margin: '1rem' }}>
        {selectedTab === "market" ?

          <button className={activeButton === "buy" ? styles.buyFinal : styles.sellFinal}
            onClick={() => handleClickBuySellButton()}
          >{activeButton === "buy" ? "Buy" : "Sell"}</button> :
          <button onClick={handleClickPlace} className={styles.sellFinal}>Place</button>}
      </div>
      {/* <div style={{position: 'absolute', borderBottom: '4px solid #E1E3EB', top: '38px', width: '100%',}}></div> */}
    </div>
  )
}