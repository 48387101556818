export const formatFilterTime = (days) => {
    const today = new Date()
    const fromDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);
    const fromDay = String(fromDate.getDate()).padStart(2, '0');
    const fromMonth = String(fromDate.getMonth() + 1).padStart(2, '0'); // Months start from 0 in JavaScript
    const fromYear = fromDate.getFullYear();

    const fromDateString = `${fromDay}/${fromMonth}/${fromYear}`
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months start from 0 in JavaScript
    const year = today.getFullYear();
    const currentDateString = `${day}/${month}/${year}`

    return `${fromDateString} - ${currentDateString}`;
};