export const formatDecimal = (value, decimalLength) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
        let decimalLengthUpToFive;
        if (decimalLength > 5) {
            decimalLengthUpToFive = 5
        } else {
            decimalLengthUpToFive = decimalLength
        }
        const roundedValue = parseFloat(parsedValue.toFixed(decimalLengthUpToFive));

        if (roundedValue % 1 === 0) {
            return roundedValue;
        }

        return roundedValue;
    }
    return value;
};
