import { create } from "zustand";
import { preloadedCurrencies } from "../utilities/preloadedCurrencies";



export const useAuthStore = create((set) => ({
    isLoggedIn: false,
    login: () => set({ isLoggedIn: true }),
    logout: () => set({ isLoggedIn: false }),
}));



export const useSession = create((set) => ({
    sessionId: null,
    deleteSessionId: () => set({ sessionId: null }),
    setSessionId: (sessionId) => set({ sessionId: sessionId }),
}));



export const useFirstLaunchState = create((set) => (
    {
        isFirstLaunch: false,
        setFirstLuanchSate: (isFirstLaunch) => set({ isFirstLaunch: isFirstLaunch }),
    })
)



export const useBaseURL = create((set) => (
    {
        baseURL: null,
        setBaseURL: (url) => set({ baseURL: url }),
        deleteBaseURL: () => set({ baseURL: null })
    }
))


export const useBrokerId = create((set) => (
    {
        brokerId: null,
        setBrokerId: (id) => set({ brokerId: id }),
        deleteBrokerId: () => set({ brokerId: null })
    }
))



export const useSocialVisible = create((set) => (
    {
        socialVisible: false,
        setSocialVisible: (condition) => set({ socialVisible: condition }),
    }
))



export const useServerName = create((set) => (
    {
        serverName: null,
        setServerName: (serverName) => set({ serverName: serverName }),
        deleteServerName: () => set({ serverName: null })
    }
))


export const useTokenStore = create((set) => (
    {
        token: null,
        setToken: (token) => set({ token: token }),
        deleteToken: () => set({ token: null })
    }
))



export const useStarredCurrenciesStore = create((set) => (
    {
        starredCurrencies: [],
        setStarredCurrencies: (names) => set({ starredCurrencies: names }),
    }
))



export const useAllCurrenciesStore = create((set) => (
    {
        allCurrencies: [],
        setAllCurrencies: (currencies) => set({ allCurrencies: currencies }),
    }
))

export const useWatchListStore = create((set) => (
    {
        watchList: preloadedCurrencies,
        setWatchList: (newWatchList) => set({ watchList: newWatchList }),
    }
))



export const useCurrentCurrency = create((set) => (
    {
        currentCurrency: "EURUSD",
        setCurrentCurrency: (name) => set({ currentCurrency: name }),
    }
))

export const useGroupStore = create((set) => (
    {
        group: "",
        setGroup: (name) => set({ group: name }),
    }
))

export const useOrderTabActive = create((set) => (
    {
        orderTabActive: false,
        setOrderTabActive: (boolean) => set({ orderTabActive: boolean }),
    }
))

export const useDesktopViewStatus = create((set) => (
    {
        desktopView: true,
        setDesktopView: (boolean) => set({ desktopView: boolean }),
    }
))

export const useWindowWidth = create((set) => (
    {
        screenWidth: 0,
        setScreenWidth: (width) => set({ screenWidth: width }),
    }
))

export const useOrderTabWidth = create((set) => (
    {
        orderTabWidth: 25,
        setOrderTabWidth: (width) => set({ orderTabWidth: width }),
    }
))