
export const saveData = (key, value) => {
    try {
        const jsonValue = JSON.stringify(value)
        localStorage.setItem(key, jsonValue);
    } catch (error) {
        console.error('Error saving data:', error);
    }
};

export const getData = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value !== null) return JSON.parse(value)
        else return null
    } catch (error) {
        console.error('Error retrieving data:', error);
        return null;
    }
};

export const removeData = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing data:', error);
    }
};