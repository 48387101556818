import styles from './Orders.module.css'
import { useEffect, useState } from 'react'
import { useAllCurrenciesStore, useCurrentCurrency, useDesktopViewStatus, useOrderTabActive, useSession, useTokenStore, useWindowWidth } from '../store'
import { formatFilterTime } from '../utilities/formatFilterTime'
import axios from 'axios'
import { getToTime } from '../utilities/getToTime'
import { formatDecimal } from '../utilities/formatDecimal'
import { Button, ConfigProvider, Tabs } from 'antd'
import StickyBox from 'react-sticky-box';
import { BsArrowUp } from "react-icons/bs";
import { BsArrowDown } from "react-icons/bs";
import { getDecimalPoint } from '../utilities/getDecimalPoint'
import { toast } from 'react-toastify'

export const Orders = ({ height, width }) => {

    const { token, setToken } = useTokenStore()
    const [userData, setUserData] = useState(null)
    const [openOrderExpandedItems, setOpenOrderExpandedItems] = useState([])
    const [pendingOrderExpandedItems, setPendingOrderExpandedItems] = useState([])
    const [tradeHistoryExpandedItems, setTradeHistoryExpandedItems] = useState([])
    const [sumProfit, setSumProfit] = useState(0);
    const [sumDeposit, setSumDeposit] = useState(0);
    const [sumWithdrawal, setSumWithdrawal] = useState(0);
    const [sumCommission, setSumCommision] = useState(0);
    const [balance, setBalance] = useState(0)
    const [openOrderData, setOpenOrderData] = useState(null)
    const [pendingOrderData, setPendingOrderData] = useState(null)
    const [tradeHistoryData, setTradeHistoryData] = useState(null)
    const [tradeHistoryRows, setTradeHistoryRows] = useState(null)
    const [filterBy, setFilterBy] = useState('')
    const [customFromTimeinDays, setCustomFromTimeinDays] = useState(0)
    const [longPressedItem, setLongPressedItem] = useState({})
    const { sessionId } = useSession()
    const [sumSwap, setSumSwap] = useState('')
    const { orderTabActive, setOrderTabActive } = useOrderTabActive()
    const [filterItems, setFilterItems] = useState([
        {
            title: "Today",
            type: "today",
            dateString: formatFilterTime(0)
        },
        {
            title: "Last Week",
            type: "week",
            dateString: formatFilterTime(7)
        },
        {
            title: "Last Month",
            type: "month",
            dateString: formatFilterTime(30)
        },
        {
            title: "Last 3 Months",
            type: "threeMonth",
            dateString: formatFilterTime(90)
        },
        // {
        //     title: "custom",
        //     type: "custom",
        //     dateString: formatFilterTime(customFromTimeinDays)
        // },
    ])






    const [selectedTab, setSelectedTab] = useState('positions')


    //get open order data
    useEffect(() => {
        const fetchOrderData = async () => {
            const baseURL = "https://proxy.jksconsultants.com";
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${baseURL}/Order/open?login=6666`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/plain',
                    Authorization: `Bearer ${token}`,
                }
            };
            await axios
                .request(config)
                .then((response) => {
                    const data = response.data.data.position;
                    const sortedOpenOrderData = data.sort((a, b) => {
                        return b.timeCreate - a.timeCreate
                    })
                    setOpenOrderData(sortedOpenOrderData)
                    const pendingOrderData = response.data.data.open;
                    setPendingOrderData(pendingOrderData)
                })
                .catch((error) => {
                    console.error("openorder", error);
                });
        }
        const fetchUserData = async () => {
            const baseURL = "https://proxy.jksconsultants.com";
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${baseURL}/Users/account?Login=6666`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/plain',
                    Authorization: `Bearer ${token}`,
                }
            };
            await axios
                .request(config)
                .then((response) => {
                    setUserData(response.data.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        if (token) fetchOrderData()
        if (token) fetchUserData();
        const intervalId = setInterval(() => {
            if (token) fetchOrderData()
            if (token) fetchUserData();
        }, 5000);

        return () => clearInterval(intervalId);

    }
        , [token])

    useEffect(() => {

        const fetchHistorData = async () => {
            const toTime = getToTime()
            const baseURL = "https://proxy.jksconsultants.com";

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${baseURL}/Order/history?login=6666&to=${toTime}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/plain',
                    Authorization: `Bearer ${token}`,
                }
            };
            await axios
                .request(config)
                .then((response) => {
                    console.log('history_data_response', response)
                    const mergedData = mergeTradeHistoryDataByPositionID(response.data.data.history)
                    const filteredData = mergedData.filter(item => (item.close_time && item.close_price))
                    setTradeHistoryData(filteredData)
                    processTradeHistoryRowsByFilter(filteredData)
                    processHistory(response.data.data.history);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        if (token) fetchHistorData();
    }, [selectedTab, token])

    const mergeTradeHistoryDataByPositionID = (data) => {
        const mergedData = {};
        data.forEach(row => {
            if (row.volume === 0) return
            if (!mergedData[row.positionID]) {
                mergedData[row.positionID] = { ...row, open_time: null, close_time: null };
            }
            mergedData[row.positionID].profit = row.profit;

            // Handling open_time and close_time
            if (row.entry === 0 && !mergedData[row.positionID].open_time) {
                mergedData[row.positionID].open_time = row.time;
                mergedData[row.positionID].open_price = row.price;
                if (row.action == 0) {
                    mergedData[row.positionID].order_type = "BUY";
                } else if (row.action == 1) {
                    mergedData[row.positionID].order_type = "SELL";
                }
            } else if (row.entry === 1 && !mergedData[row.positionID].close_time) {
                mergedData[row.positionID].close_time = row.time;
                mergedData[row.positionID].close_price = row.price;
            }
        });


        return Object.values(mergedData);
    };

    const processHistory = (history) => {
        if (history) {
            let localSumProfit = 0;
            let localSumDeposit = 0;
            let localSumWithdrawal = 0;
            let localBalance = 0;
            let localSumCommission = 0;
            let swap = 0;
            history.forEach(row => {
                if (row.action === 2 && row.profit > 0) {
                    // 'Deposit' logic
                    localSumDeposit += row.profit;
                }
                else if (row.action === 2 && row.profit < 0) {
                    localSumWithdrawal += row.profit;
                }
                else localSumProfit += row.profit + row.commission;
                localSumCommission += row.commission;
                swap += row.storage;
            });

            localBalance = localSumDeposit + localSumProfit + localSumWithdrawal;
            setSumProfit(localSumProfit.toFixed(2));
            setSumDeposit(localSumDeposit.toFixed(2));
            setSumWithdrawal(localSumWithdrawal.toFixed(2));
            setBalance(localBalance.toFixed(2))
            setSumCommision(localSumCommission)
            setSumSwap(formatDecimal(swap, 2))
        }
    };

    const processTradeHistoryRows = (days, data) => {
        const processedData = [];
        const now = new Date();
        now.setHours(now.getHours() + 6); // Adjusting the current time by adding 6 hours
        const daysAgo = new Date(now);
        daysAgo.setDate(now.getDate() - days);
        data.forEach(row => {
            if (row.action !== 2) {
                const closeTime = new Date(row.close_time * 1000);
                if (closeTime >= daysAgo && closeTime <= now) {
                    processedData.push({ ...row, closeTime: closeTime.toISOString() });
                }
            }
        });
        const sortedProcessedData = processedData.sort((a, b) => {
            return b.close_time - a.close_time
        })
        setTradeHistoryRows(sortedProcessedData)
    }

    const processTradeHistoryRowsByFilter = (data) => {
        if (filterBy === 'today') {
            processTradeHistoryRows(1, data)
        }
        else if (filterBy === 'week') {
            processTradeHistoryRows(7, data)
        }
        else if (filterBy === 'month') {
            processTradeHistoryRows(30, data)
        }
        else if (filterBy === 'threeMonths') {
            processTradeHistoryRows(90, data)
        } else {
            const sortedData = data.sort((a, b) => {
                return b.close_time - a.close_time
            })
            setTradeHistoryRows(sortedData)
        }
    }



    const handlePressOpenOrderItems = (itemPosition) => {
        if (openOrderExpandedItems.includes(itemPosition)) {
            const newArray = openOrderExpandedItems.filter(item => item !== itemPosition);
            setOpenOrderExpandedItems(newArray)
        }
        else {
            setOpenOrderExpandedItems([...openOrderExpandedItems, itemPosition])
        }
    }
    const handlePressPendingOrderItems = (itemPosition) => {
        if (pendingOrderExpandedItems.includes(itemPosition)) {
            const newArray = pendingOrderExpandedItems.filter(item => item !== itemPosition);
            setPendingOrderExpandedItems(newArray)
        }
        else {
            setPendingOrderExpandedItems([...pendingOrderExpandedItems, itemPosition])
        }
    }
    const { setCurrentCurrency } = useCurrentCurrency()


    const handlePressTradeHistoryItems = (itemPosition) => {
        if (tradeHistoryExpandedItems.includes(itemPosition)) {
            const newArray = tradeHistoryExpandedItems.filter(item => item !== itemPosition);
            setTradeHistoryExpandedItems(newArray)
        }
        else {
            setTradeHistoryExpandedItems([...tradeHistoryExpandedItems, itemPosition])
        }
    }


    const { allCurrencies } = useAllCurrenciesStore()


    const getOrderType = (typeParameter) => {
        let type;
        if (typeParameter == 2) type = "Buy Limit"
        if (typeParameter == 3) type = "Sell Limit"
        if (typeParameter == 4) type = "Buy Stop"
        if (typeParameter == 5) type = "Sell Limit"
        return type
    }


    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox
            offsetTop={64}
            offsetBottom={20}
            style={{
                zIndex: 1,
            }}
        >
            <DefaultTabBar
                {...props}
                style={{
                    color: '#ffffff'
                }}
            />
        </StickyBox>
    );

    const items = [
        {
            label: `Positions`,
            key: "positions",
        },
        // {
        //     label: `Orders`,
        //     key: "orders",

        // },
        {
            label: `History`,
            key: "history",
        },
    ]

    const handleSelectRow = (symbol) => {
        setCurrentCurrency(symbol)
        setOrderTabActive(true)
    }

    const [positionsActiveTab, setPositionsActiveTab] = useState("")
    const [positionsActiveTabOrder, setPositionsActiveTabOrder] = useState("")
    const [historyActiveTab, setHistoryActiveTab] = useState("")
    const [historyActiveTabOrder, setHistoryActiveTabOrder] = useState("")

    const handleClickColumn = (type, tabName) => {
        if (type === "positions") {
            if (tabName === positionsActiveTab) {
                if (positionsActiveTabOrder === "increase") { setPositionsActiveTabOrder("decrease") }
                else {
                    setPositionsActiveTab("")
                    setPositionsActiveTabOrder("")
                }
            }
            else {
                setPositionsActiveTab(tabName)
                setPositionsActiveTabOrder("increase")
            }
        }
        else if (type === "history") {
            if (tabName === historyActiveTab) {
                if (historyActiveTabOrder === "increase") { setHistoryActiveTabOrder("decrease") }
                else {
                    setHistoryActiveTab("")
                    setHistoryActiveTabOrder("")
                }
            }
            else {
                setHistoryActiveTab(tabName)
                setHistoryActiveTabOrder("increase")
            }
        }

    }

    useEffect(() => {
        if (historyActiveTab && historyActiveTabOrder) {
            if (historyActiveTabOrder === "increase") {
                if (historyActiveTab === "symbol") {
                    console.log("sort-history-increase-symbol")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.symbol.localeCompare(b.symbol));
                    setTradeHistoryRows(newTradeHisotry)
                }
                if (historyActiveTab === "position") {
                    console.log("sort-history-increase-position")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.positionID - b.positionID);
                    setTradeHistoryRows(newTradeHisotry)

                }
                if (historyActiveTab === "type") {
                    console.log("sort-history-increase-type")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.action - a.action);
                    setTradeHistoryRows(newTradeHisotry)


                }
                if (historyActiveTab === "volume") {
                    console.log("sort-history-increase-volume")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.volume - b.volume);
                    setTradeHistoryRows(newTradeHisotry)



                }
                if (historyActiveTab === "openPrice") {
                    console.log("sort-history-increase-openPrice")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.open_price - b.open_price);
                    setTradeHistoryRows(newTradeHisotry)


                }
                if (historyActiveTab === "closingPrice") {
                    console.log("sort-history-increase-closingPrice")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.close_price - b.close_price);
                    setTradeHistoryRows(newTradeHisotry)


                }
                if (historyActiveTab === "openTime") {
                    console.log("sort-history-increase-openTime")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.time - b.time);
                    setTradeHistoryRows(newTradeHisotry)
                }

                if (historyActiveTab === "closeTime") {
                    console.log("sort-history-increase-closeTime")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.close_time - b.close_time);
                    setTradeHistoryRows(newTradeHisotry)
                }
                if (historyActiveTab === "sl") {
                    console.log("sort-history-increase-sl")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.priceSL - b.priceSL);
                    setTradeHistoryRows(newTradeHisotry)

                }
                if (historyActiveTab === "tp") {
                    console.log("sort-history-increase-tp")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.priceTP - b.priceTP);
                    setTradeHistoryRows(newTradeHisotry)
                }
            }
            else {
                if (historyActiveTab === "symbol") {
                    console.log("sort-history-decrease-symbol")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.symbol.localeCompare(a.symbol));
                    setTradeHistoryRows(newTradeHisotry)
                }
                if (historyActiveTab === "position") {
                    console.log("sort-history-decrease-position")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.positionID - a.positionID);
                    setTradeHistoryRows(newTradeHisotry)

                }
                if (historyActiveTab === "type") {
                    console.log("sort-history-decrease-type")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => a.action - b.action);
                    setTradeHistoryRows(newTradeHisotry)


                }
                if (historyActiveTab === "volume") {
                    console.log("sort-history-decrease-volume")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.volume - a.volume);
                    setTradeHistoryRows(newTradeHisotry)



                }
                if (historyActiveTab === "openPrice") {
                    console.log("sort-history-decrease-openPrice")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.open_price - a.open_price);
                    setTradeHistoryRows(newTradeHisotry)


                }
                if (historyActiveTab === "closingPrice") {
                    console.log("sort-history-decrease-closingPrice")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.close_price - a.close_price);
                    setTradeHistoryRows(newTradeHisotry)


                }
                if (historyActiveTab === "openTime") {
                    console.log("sort-history-decrease-openTime")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.time - a.time);
                    setTradeHistoryRows(newTradeHisotry)
                }

                if (historyActiveTab === "closeTime") {
                    console.log("sort-history-decrease-closeTime")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.close_time - a.close_time);
                    setTradeHistoryRows(newTradeHisotry)
                }
                if (historyActiveTab === "sl") {
                    console.log("sort-history-decrease-sl")

                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.priceSL - a.priceSL);
                    setTradeHistoryRows(newTradeHisotry)

                }
                if (historyActiveTab === "tp") {
                    console.log("sort-history-decrease-tp")
                    const newTradeHisotry = tradeHistoryRows.sort((a, b) => b.priceTP - a.priceTP);
                    setTradeHistoryRows(newTradeHisotry)
                }
            }
        }

    }, [historyActiveTab, historyActiveTabOrder])

    useEffect(() => {
        if (positionsActiveTab && positionsActiveTabOrder) {
            if (positionsActiveTabOrder === "increase") {
                if (positionsActiveTab === "symbol") {
                    console.log("sort-positions-increase-symbol")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.symbol.localeCompare(b.symbol));
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "position") {
                    console.log("sort-positions-increase-position")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.position - b.position);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "action") {
                    console.log("sort-positions-increase-action")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.action - a.action);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "volume") {
                    console.log("sort-positions-increase-volume")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.volume - b.volume);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceOpen") {
                    console.log("sort-positions-increase-priceOpen")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.priceOpen - b.priceOpen);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceCurrent") {
                    console.log("sort-positions-increase-priceCurrent")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.priceCurrent - b.priceCurrent);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "timeCreate") {
                    console.log("sort-positions-increase-timeCreate")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.timeCreate - b.timeCreate);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "storage") {
                    console.log("sort-positions-increase-storage")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.storage - b.storage);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceSL") {
                    console.log("sort-positions-increase-priceSL")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.priceSL - b.priceSL);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceTP") {
                    console.log("sort-positions-increase-priceTP")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.priceTP - b.priceTP);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "profit") {
                    console.log("sort-positions-increase-profit")
                    const newOpenOrderData = openOrderData.sort((a, b) => a.profit - b.profit);
                    setOpenOrderData(newOpenOrderData)
                }
            }
            else {
                if (positionsActiveTab === "symbol") {
                    console.log("sort-positions-decrease-symbol")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.symbol.localeCompare(a.symbol));
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "position") {
                    console.log("sort-positions-decrease-position")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.position - a.position);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "action") {
                    console.log("sort-positions-decrease-action")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.action - a.action);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "volume") {
                    console.log("sort-positions-decrease-volume")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.volume - a.volume);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceOpen") {
                    console.log("sort-positions-decrease-priceOpen")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.priceOpen - a.priceOpen);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceCurrent") {
                    console.log("sort-positions-decrease-priceCurrent")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.priceCurrent - a.priceCurrent);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "timeCreate") {
                    console.log("sort-positions-decrease-timeCreate")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.timeCreate - a.timeCreate);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "storage") {
                    console.log("sort-positions-decrease-storage")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.storage - a.storage);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceSL") {
                    console.log("sort-positions-decrease-priceSL")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.priceSL - a.priceSL);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "priceTP") {
                    console.log("sort-positions-decrease-priceTP")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.priceTP - a.priceTP);
                    setOpenOrderData(newOpenOrderData)
                }
                if (positionsActiveTab === "profit") {
                    console.log("sort-positions-decrease-profit")
                    const newOpenOrderData = openOrderData.sort((a, b) => b.profit - a.profit);
                    setOpenOrderData(newOpenOrderData)
                }
            }
        }

    }, [positionsActiveTab, positionsActiveTabOrder])


    const closeOrder = async (position, volume) => {
        console.log("closorder")
        const volumeToSend = parseInt(parseFloat(volume) * 10000)
        const baseURL = "https://proxy.jksconsultants.com";
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/Position/close?positionID=${position}&volume=${volumeToSend}`
            ,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain',
                Authorization: `Bearer ${token}`,
            }
        };
        await axios
            .request(config)
            .then((response) => {
                const responseMessage = response.data.message
                if (responseMessage === "Request Created Successfully") {
                    toast("Closed successfully")
                }
                else {
                    toast(responseMessage);
                }
            })
            .catch((error) => {
                if (error.response.status == 500) {
                    toast("Closed successfully")

                }
                else {
                    toast('Error closing order');
                }

            });
    }


    const { desktopView } = useDesktopViewStatus()
    const { screenWidth, setScreenWidth } = useWindowWidth();

    if (desktopView) return (
        <div style={{ display: 'flex', flexDirection: 'column', }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div style={{ padding: '.5rem', paddingLeft: '1rem', height: '5px' }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {(selectedTab === 'orders' || selectedTab === 'positions') &&
                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '1rem', height: '40px' }}>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Account ID</div>
                                <div className={styles.info}>{sessionId}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Balance</div>
                                <div className={styles.info}>{userData && ((userData.balance).toFixed(2))}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Equity</div>
                                <div className={styles.info}>{userData && ((userData.equity).toFixed(2))}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Margin</div>
                                <div className={styles.info}>{userData && ((userData.margin).toFixed(2))}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Free Margin</div>
                                <div className={styles.info}>{userData && ((userData.marginFree).toFixed(2))}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Margin Level</div>
                                <div className={styles.info}>{userData && ((userData.marginLevel).toFixed(2))}</div>
                            </div>
                        </div>}

                    {(selectedTab === 'history') &&
                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '1rem', height: '40px' }}>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Account ID</div>
                                <div className={styles.info}>{sessionId}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Profit</div>
                                <div className={styles.info}>{tradeHistoryData && sumProfit}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Deposit</div>
                                <div className={styles.info}>{tradeHistoryData && sumDeposit}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Swap</div>
                                <div className={styles.info}>{tradeHistoryData && sumSwap}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Commission</div>
                                <div className={styles.info}>{tradeHistoryData && sumCommission.toFixed(2)}</div>
                            </div>
                            <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Balance</div>
                                <div className={styles.info}>{tradeHistoryData && balance}</div>
                            </div>
                        </div>}

                    <Tabs style={{ paddingLeft: '1rem', paddingRight: '1rem', height: '70px' }}
                        defaultActiveKey="1"
                        items={items}
                        onChange={(key) => setSelectedTab(key)}
                    />
                    {selectedTab === 'positions' &&
                        <div key="positions">
                            <div className={styles.ordersRow}  style={{
                                margin: '1rem', height: '15px',
                            }}>
                                <div className={styles.symbolTitle} onClick={() => handleClickColumn("positions", "symbol")}>
                                    <div>Symbol</div>
                                    {positionsActiveTab === "symbol" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "position")}>
                                    <div>Position</div>
                                    {positionsActiveTab === "position" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "type")}>
                                    <div>Type</div>
                                    {positionsActiveTab === "type" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "volume")}>
                                    <div>Volume</div>
                                    {positionsActiveTab === "volume" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "openPrice")}>
                                    <div>Open price</div>
                                    {positionsActiveTab === "openPrice" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "currentPrice")}>
                                    <div>Current price</div>
                                    {positionsActiveTab === "currentPrice" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "openTime")}>
                                    <div>Open time</div>
                                    {positionsActiveTab === "openTime" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "swap")}>
                                    <div>Swap</div>
                                    {positionsActiveTab === "swap" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "sl")}>
                                    <div>SL</div>
                                    {positionsActiveTab === "sl" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "tp")}>
                                    <div>TP</div>
                                    {positionsActiveTab === "tp" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "profit")}>
                                    <div>Profit</div>
                                    {positionsActiveTab === "profit" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                            </div>
                            {openOrderData && <div   style={{ height: height - 180, overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}> {openOrderData.map((item, index) =>
                                <div className={styles.ordersRow} key={`openOrder-${index}`} onClick={() => handleSelectRow(item.symbol)} style={{
                                     margin: '1rem', marginTop: 0, justifyContent: 'space-between', borderBottom: "1px solid #E1E3EB", alignItems: 'center'
                                }}>
                                    <div className={styles.symbolCol}>{item.symbol}</div>
                                    <div className={styles.orderCol}>{item.position}</div>
                                    <div className={styles.orderCol}>{item.action == 0 ? "Buy" : "Sell"}</div>
                                    <div className={styles.orderCol}>{item.volume / 10000}</div>
                                    <div className={styles.orderCol}>{item.priceOpen.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                    <div className={styles.orderCol}>{item.priceCurrent.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                    <div className={styles.orderCol}>{item.timeCreate}</div>
                                    <div className={styles.orderCol}>{formatDecimal(item.storage, 2)}</div>
                                    <div className={styles.orderCol}>{item.priceSL}</div>
                                    <div className={styles.orderCol}>{item.priceTP}</div>
                                    <div className={styles.orderCol}>{item.profit}</div>
                                    <div style={{justifyContent: 'center', alignItems: 'center', marginTop: '.25rem', marginBottom: '.25rem'}} className={styles.orderCol}><Button onClick={(e) => { e.stopPropagation(); closeOrder(item.position, item.volume) }} className={styles.closeBtn}>Close</Button></div>
                                </div>)
                            } </div>}
                        </div>}

                    {
                        selectedTab === 'orders' && <div>
                            <div style={{
                                display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'space-between', height: '15px'
                            }}>
                                <div className={styles.symbolTitle} onClick={() => handleClickColumn("history", "symbol")}>
                                    <div>Symbol</div>
                                    {historyActiveTab === "symbol" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "position")}>
                                    <div>Position</div>
                                    {historyActiveTab === "position" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "type")}>
                                    <div>Type</div>
                                    {historyActiveTab === "type" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "volume")}>
                                    <div>Volume</div>
                                    {historyActiveTab === "volume" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openPrice")}>
                                    <div>Open price</div>
                                    {historyActiveTab === "openPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "currentPrice")}>
                                    <div>Current price</div>
                                    {historyActiveTab === "currentPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openTime")}>
                                    <div>Open time</div>
                                    {historyActiveTab === "openTime" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>

                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "swap")}>
                                    <div>Swap</div>
                                    {historyActiveTab === "swap" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "sl")}>
                                    <div>SL</div>
                                    {historyActiveTab === "sl" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "tp")}>
                                    <div>TP</div>
                                    {historyActiveTab === "tp" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                            </div>
                            {pendingOrderData && <div style={{ height: height - 180, overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}> {pendingOrderData.map((item, index) =>
                                <div key={`pendingOrder-${index}`} onClick={() => handleSelectRow(item.symbol)} className={styles.row} style={{
                                    display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between',
                                }}>
                                    <div className={styles.symbolCol}>{item.symbol}</div>
                                    <div className={styles.orderCol}>{item.position}</div>
                                    <div className={styles.orderCol}>{item.action == 0 ? "Buy" : "Sell"}</div>
                                    <div className={styles.orderCol}>{item.volume / 10000}</div>
                                    <div className={styles.orderCol}>{item.priceOpen}</div>
                                    <div className={styles.orderCol}>{item.priceCurrent}</div>
                                    <div className={styles.orderCol}>{item.timeCreate}</div>
                                    <div className={styles.orderCol}>{formatDecimal(item.storage,2)}</div>
                                    <div className={styles.orderCol}>{item.priceSL}</div>
                                    <div className={styles.orderCol}>{item.priceTP}</div>
                                </div>)
                            } </div>}

                        </div>
                    }

                    {
                        selectedTab === 'history' && <div  style={{ display: 'flex', flex: 1, flexDirection: 'column', }}>
                            <div className={styles.historyRow} style={{
                                 margin: '1rem', height: '15px',
                            }}>
                                <div className={styles.symbolTitle} onClick={() => handleClickColumn("history", "symbol")}>
                                    <div>Symbol</div>
                                    {historyActiveTab === "symbol" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "position")}>
                                    <div>Position</div>
                                    {historyActiveTab === "position" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "type")}>
                                    <div>Type</div>
                                    {historyActiveTab === "type" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "volume")}>
                                    <div>Volume</div>
                                    {historyActiveTab === "volume" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openPrice")}>
                                    <div>Open price</div>
                                    {historyActiveTab === "openPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "closingPrice")}>
                                    <div>Closing price</div>
                                    {historyActiveTab === "closingPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openTime")}>
                                    <div>Open time</div>
                                    {historyActiveTab === "openTime" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "closeTime")}>
                                    <div>Close time</div>
                                    {historyActiveTab === "closeTime" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "sl")}>
                                    <div>SL</div>
                                    {historyActiveTab === "sl" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>
                                <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "tp")}>
                                    <div>TP</div>
                                    {historyActiveTab === "tp" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                                </div>

                            </div>
                            {tradeHistoryRows && <div  style={{ height: height - 180, overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}> {tradeHistoryRows.map((item, index) =>
                                <div className={styles.historyRow} key={`tradeHistoryRows-${index}`} onClick={() => handleSelectRow(item.symbol)}  style={{
                                     padding: '1rem',  borderBottom: "1px solid #E1E3EB"

                                }}>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.symbolCol}>{item.symbol}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.positionID}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.action == 0 ? "Buy" : "Sell"}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.volume / 10000}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.open_price.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.close_price.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.open_time}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.close_time}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.priceSL}</div>
                                    <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.priceTP}</div>
                                </div>)}
                            </div>
                            }

                        </div>
                    }
                </div>
            </div>
        </div>
    )
    else return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ padding: '.5rem', paddingLeft: '1rem', height: '5px' }}>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                {(selectedTab === 'orders' || selectedTab === 'positions') &&
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '1rem', height: '40px', overflowX: 'scroll', width: `${screenWidth}px` }}>
                        <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Account ID</div>
                                <div className={styles.info}>{sessionId}</div>
                            </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Balance</div>
                            <div className={styles.info}>{userData && ((userData.balance).toFixed(2))}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Equity</div>
                            <div className={styles.info}>{userData && ((userData.equity).toFixed(2))}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Margin</div>
                            <div className={styles.info}>{userData && ((userData.margin).toFixed(2))}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Free Margin</div>
                            <div className={styles.info}>{userData && ((userData.marginFree).toFixed(2))}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Margin Level</div>
                            <div className={styles.info}>{userData && ((userData.marginLevel).toFixed(2))}</div>
                        </div>
                    </div>}

                {(selectedTab === 'history') &&
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '1rem', height: '40px', overflowX: 'scroll', width: `${screenWidth}px` }}>
                        <div className={styles.infoCol}>
                                <div className={styles.infoTitle}>Account ID</div>
                                <div className={styles.info}>{sessionId}</div>
                            </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Profit</div>
                            <div className={styles.info}>{tradeHistoryData && sumProfit}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Deposit</div>
                            <div className={styles.info}>{tradeHistoryData && sumDeposit}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Swap</div>
                            <div className={styles.info}>{tradeHistoryData && sumSwap}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Commission</div>
                            <div className={styles.info}>{tradeHistoryData && sumCommission.toFixed(2)}</div>
                        </div>
                        <div className={styles.infoCol}>
                            <div className={styles.infoTitle}>Balance</div>
                            <div className={styles.info}>{tradeHistoryData && balance}</div>
                        </div>
                    </div>}

                <Tabs style={{ paddingLeft: '1rem', paddingRight: '1rem', height: '70px', width: `${screenWidth}px`,}}
                    defaultActiveKey="1"
                    items={items}
                    onChange={(key) => setSelectedTab(key)}
                />
                {selectedTab === 'positions' &&
                    <div key="positions" style={{ width: `${screenWidth}px`, overflowX: 'scroll' }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'space-between', height: '15px',
                        }}>
                            <div className={styles.symbolTitle} onClick={() => handleClickColumn("positions", "symbol")}>
                                <div>Symbol</div>
                                {positionsActiveTab === "symbol" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "position")}>
                                <div>Position</div>
                                {positionsActiveTab === "position" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "type")}>
                                <div>Type</div>
                                {positionsActiveTab === "type" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "volume")}>
                                <div>Volume</div>
                                {positionsActiveTab === "volume" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "openPrice")}>
                                <div>Open price</div>
                                {positionsActiveTab === "openPrice" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "currentPrice")}>
                                <div>Current price</div>
                                {positionsActiveTab === "currentPrice" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "openTime")}>
                                <div>Open time</div>
                                {positionsActiveTab === "openTime" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "swap")}>
                                <div>Swap</div>
                                {positionsActiveTab === "swap" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "sl")}>
                                <div>SL</div>
                                {positionsActiveTab === "sl" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "tp")}>
                                <div>TP</div>
                                {positionsActiveTab === "tp" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("positions", "profit")}>
                                <div>Profit</div>
                                {positionsActiveTab === "profit" && (<div style={{}}>{positionsActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                        </div>
                        {openOrderData && <div style={{ width: '66rem', overflowX: 'hidden', overflowY: 'scroll', display: 'flex', flexDirection: 'column', height: 'calc(90vh - 130px)' }}> {openOrderData.map((item, index) =>
                            <div key={`openOrder-${index}`} onClick={() => handleSelectRow(item.symbol)} className={styles.row} style={{
                                display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between', borderBottom: "1px solid #E1E3EB"
                            }}>
                                <div className={styles.symbolCol}>{item.symbol}</div>
                                <div className={styles.orderCol}>{item.position}</div>
                                <div className={styles.orderCol}>{item.action == 0 ? "Buy" : "Sell"}</div>
                                <div className={styles.orderCol}>{item.volume / 10000}</div>
                                <div className={styles.orderCol}>{item.priceOpen.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                <div className={styles.orderCol}>{item.priceCurrent.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                <div className={styles.orderCol}>{item.timeCreate}</div>
                                <div className={styles.orderCol}>{formatDecimal(item.storage,2)}</div>
                                <div className={styles.orderCol}>{item.priceSL}</div>
                                <div className={styles.orderCol}>{item.priceTP}</div>
                                <div className={styles.orderCol}>{item.profit}</div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}><Button onClick={(e) => { e.stopPropagation(); closeOrder(item.position, item.volume) }} style={{ position: 'absolute', left: width - 60 }} className={styles.closeBtn}>Close</Button></div>
                            </div>)
                        } </div>}
                    </div>}

                {/* {
                selectedTab === 'orders' && <div  style={{width: `${screenWidth}px`, overflow: 'scroll'}}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'space-between', height: '15px'
                    }}>
                        <div className={styles.symbolTitle} onClick={() => handleClickColumn("history", "symbol")}>
                            <div>Symbol</div>
                            {historyActiveTab === "symbol" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "position")}>
                            <div>Position</div>
                            {historyActiveTab === "position" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "type")}>
                            <div>Type</div>
                            {historyActiveTab === "type" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "volume")}>
                            <div>Volume</div>
                            {historyActiveTab === "volume" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openPrice")}>
                            <div>Open price</div>
                            {historyActiveTab === "openPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "currentPrice")}>
                            <div>Current price</div>
                            {historyActiveTab === "currentPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openTime")}>
                            <div>Open time</div>
                            {historyActiveTab === "openTime" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>

                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "swap")}>
                            <div>Swap</div>
                            {historyActiveTab === "swap" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "sl")}>
                            <div>SL</div>
                            {historyActiveTab === "sl" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                        <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "tp")}>
                            <div>TP</div>
                            {historyActiveTab === "tp" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                        </div>
                    </div>
                    {pendingOrderData && <div style={{ height: height - 180, overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}> {pendingOrderData.map((item, index) =>
                        <div key={`pendingOrder-${index}`} onClick={() => handleSelectRow(item.symbol)} className={styles.row} style={{
                            display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between',
                        }}>
                            <div className={styles.symbolCol}>{item.symbol}</div>
                            <div className={styles.orderCol}>{item.position}</div>
                            <div className={styles.orderCol}>{item.action == 0 ? "Buy" : "Sell"}</div>
                            <div className={styles.orderCol}>{item.volume / 10000}</div>
                            <div className={styles.orderCol}>{item.priceOpen}</div>
                            <div className={styles.orderCol}>{item.priceCurrent}</div>
                            <div className={styles.orderCol}>{item.timeCreate}</div>
                            <div className={styles.orderCol}>{item.storage}</div>
                            <div className={styles.orderCol}>{item.priceSL}</div>
                            <div className={styles.orderCol}>{item.priceTP}</div>
                        </div>)
                    } </div>}

                </div>
            } */}

                {
                    selectedTab === 'history' && <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: `${screenWidth}px`, overflowX: 'scroll' }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'space-between', height: '15px',
                        }}>
                            <div className={styles.symbolTitle} onClick={() => handleClickColumn("history", "symbol")}>
                                <div>Symbol</div>
                                {historyActiveTab === "symbol" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}
                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "position")}>
                                <div>Position</div>
                                {historyActiveTab === "position" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "type")}>
                                <div>Type</div>
                                {historyActiveTab === "type" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "volume")}>
                                <div>Volume</div>
                                {historyActiveTab === "volume" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openPrice")}>
                                <div>Open price</div>
                                {historyActiveTab === "openPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "closingPrice")}>
                                <div>Closing price</div>
                                {historyActiveTab === "closingPrice" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "openTime")}>
                                <div>Open time</div>
                                {historyActiveTab === "openTime" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "closeTime")}>
                                <div>Close time</div>
                                {historyActiveTab === "closeTime" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "sl")}>
                                <div>SL</div>
                                {historyActiveTab === "sl" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>
                            <div className={styles.orderTitles} onClick={() => handleClickColumn("history", "tp")}>
                                <div>TP</div>
                                {historyActiveTab === "tp" && (<div style={{}}>{historyActiveTabOrder === "increase" ? <BsArrowUp /> : <BsArrowDown />}</div>)}

                            </div>

                        </div>
                        {tradeHistoryRows && <div style={{ width: '60rem', overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column', height: 'calc(90vh - 130px)' }}> {tradeHistoryRows.map((item, index) =>
                            <div key={`tradeHistoryRows-${index}`} onClick={() => handleSelectRow(item.symbol)} className={styles.row} style={{
                                display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between', borderBottom: "1px solid #E1E3EB"

                            }}>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.symbolCol}>{item.symbol}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.positionID}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.action == 0 ? "Buy" : "Sell"}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.volume / 10000}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.open_price.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.close_price.toFixed(getDecimalPoint(item.symbol, allCurrencies))}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.open_time}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.close_time}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.priceSL}</div>
                                <div key={`tradeHistoryRow${index}Value`} className={styles.orderCol}>{item.priceTP}</div>
                            </div>)}
                        </div>
                        }

                    </div>
                }
            </div>
        </div>
    )
}